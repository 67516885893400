.App {
  font-family: sans-serif;
  text-align: center;
}

.font-nunito {
  font-family: nunito, sans-serif;
}

.font-roboto {
  font-family: roboto, sans-serif;
}
.font-poppins {
  font-family: poppins, sans-serif;
}
